import clss from './styles.module.css';
import {useCallback} from "@/shared/useCallback";

const CLASS_MODAL_LOCK = 'modal-lock';

const createOverlay = () => {
    const overlay = document.createElement('div');
    overlay.classList.add(clss['overlay']);
    return overlay;
};

const createModal = ({text, heading}) => {
    const modal = document.createElement('div');
    modal.classList.add(clss['action-modal']);

    const closeBtn = document.createElement('button');
    closeBtn.setAttribute('type', 'button');
    closeBtn.classList.add(clss['action-modal__close-btn']);

    const textWrapper = document.createElement('p');
    textWrapper.classList.add(clss['action-modal__message']);
    textWrapper.textContent = text;

    const headingNode = document.createElement('span');
    headingNode.classList.add(clss['action-modal__heading']);
    headingNode.textContent = heading;

    modal.append(closeBtn, headingNode, textWrapper);
    return [modal, closeBtn];
};

export function createActionModal({text, heading, onShow, onClose} = {}) {
    const onShowCb = useCallback(onShow);
    const onCloseCb = useCallback(onClose);

    const [modalBody, closeBtn] = createModal({text, heading});
    const modal = createOverlay();
    modal.append(modalBody);

    const close = () => {
        document.body.classList.remove(clss['modal-lock']);
        document.body.removeChild(modal);
        onCloseCb.call();
    };
    const show = () => {
        document.body.classList.add(clss['modal-lock']);
        document.body.append(modal);
        closeBtn.addEventListener(
            'click',
            () => {
                close();
            },
            {once: true}
        );
        onShowCb.call();
    };

    return {
        show,
        close,
        onClose: onCloseCb.set,
        onShow: onShowCb.set,
    };
}

export const useActionModal = (props) => {
    const modal = createActionModal(props);
    modal.show();
};
